<template>
  <article class="l-page l-page--login l-page--two-cols">
    <main class="l-page__main">
      <section class="l-page__logo">
        <img class="o-image" alt="Vue logo" src="@rs-911/assets/rs911.svg">
        <hr>
        <b-card title="Welcome" sub-title="Introduction">
          <b-card-text>
            Welcome to the Real Safe 911 online portal.  
          </b-card-text>
          <b-card-text>
            <b-button v-b-modal.modal-show-content-vt variant="primary-alt">
              Watch Video
            </b-button>
          </b-card-text>
        </b-card>
      </section>

      <section class="l-page__body">
        <header class="l-page__header">
          <h1 class="o-title">
            E-mail verification
          </h1>
        </header>
        <section class="c-mail-confirmation">
          <div v-if="!fail && !success">
            <h4>E-mail validating...</h4>
          </div>
          <div v-if="success" class="mail-success">
            <img src="@rs-911/assets/mail-confirm.svg" class="o-image mb-5">
            <h4 class="message">
              Thank you, you successfuly confirmed e-mail
            </h4>
          </div>
          <div v-if="fail" class="mail-fail">
            <img src="@rs-911/assets/canceled.svg" class="o-image mb-5">
            <h4 class="message">
              Sorry, this link is invalid or expired
            </h4>
          </div>
        </section>
      </section>
      <ShowContent :id="'modal-show-content-vt'"
                   :vid-link="'https://media-hosting-rsw.s3.amazonaws.com/FR_instructions.mp4'" 
                   :title="'Video Tutorial'" />
    </main>
  </article>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import ShowContent from '@shared/components/ShowContent.vue';

@Component({
  components: {
		ShowContent
  }
})
export default class MailConfirmation extends Vue {
  fail = false;
  success = false;

  created() {
    // setTimeout(() => this.success = true, 3000);
    if(!this.$route.query.Token || !this.$route.query.UserId) {
      this.fail = true;
      return;
    }

    this.$store.dispatch('auth/confirmSecondPartnerMail', {
      UserId: this.$route.query.UserId, Token: this.$route.query.Token
    }).then(response => {
      console.log(response);
      this.success = true;
    }).catch(err => {
      console.log(err);
      this.fail = true;
    });
  }
}
</script>

<style scoped>
.c-mail-confirmation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
